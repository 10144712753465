import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import New from './pages/new/New';
import { patientInputs, userInputs } from './formSource';
import './style/dark.scss';
import { auth } from './firebase'; // Asigură-te că ai importat obiectul auth din firebase.js
import { useContext } from 'react';
import { DarkModeContext } from './contex/darkModeContext';
import PatientDetails from './components/patientDetails/PatientDetails.jsx';
import DoctorDetails from './components/doctorDetails/DoctorDetails';
import Orders from './pages/orders/Orders';
import Stats from './pages/stats/Stats';
import Appointments from './pages/appointments/Appointments';
import Patients from './pages/patients/Patients';
import Doctors from './pages/doctors/Doctors';


function App() {
  const { darkMode } = useContext(DarkModeContext);
  const [currentUser, setCurrentUser] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <div className={darkMode ? 'app dark' : 'app'}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="/login" element={<Login />} />
            <Route index element={<RequireAuth><Home /></RequireAuth>} />

              <Route path="/doctors">
                <Route index element={<RequireAuth><Doctors toggleSidebar={toggleSidebar} /></RequireAuth>} />
                <Route path="/doctors/:id" element={<RequireAuth><DoctorDetails /></RequireAuth>} />
                <Route path="new" element={<RequireAuth><New inputs={userInputs} title="Add new user" /></RequireAuth>} />
              </Route>

            <Route path="/patients">
              <Route index element={<RequireAuth><Patients toggleSidebar={toggleSidebar}/></RequireAuth>} />
              <Route path="/patients/:id" element={<RequireAuth><PatientDetails /></RequireAuth>} />
              <Route path="new" element={<RequireAuth><New inputs={patientInputs} title="Add new patient" /></RequireAuth>} />
            </Route>

            <Route path="/orders" element={<RequireAuth><Orders toggleSidebar={toggleSidebar} /></RequireAuth>} />
            <Route path="/appointments" element={<RequireAuth><Appointments toggleSidebar={toggleSidebar} /></RequireAuth>} />
            <Route path="/stats" element={<RequireAuth><Stats toggleSidebar={toggleSidebar} /></RequireAuth>} />

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
