// Orders.jsx
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import Widget from "../../components/widget/Widget"
import { db } from "../../firebase";
import OrderList from '../../components/orderList/OrderList';
import "./orders.scss"

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [totalPatientsDay, setTotalPatientsDay] = useState(0);
  const [totalPatientsMonth, setTotalPatientsMonth] = useState(0);
  const [totalPatientsYear, setTotalPatientsYear] = useState(0);
  const [totalEarningsDay, setTotalEarningsDay] = useState(0);
  const [totalEarningsMonth, setTotalEarningsMonth] = useState(0);
  const [totalEarningsYear, setTotalEarningsYear] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [clickLocked, setClickLocked] = useState(false);

  


  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'visits'));
        const ordersData = [];
        let earningsDay = 0;
        let earningsMonth = 0;
        let earningsYear = 0;
        let patientsCountDay = 0;
        let patientsCountMonth = 0;
        let patientsCountYear = 0;
        const currentDate = new Date();
    
        for (const docRef of querySnapshot.docs) {
          const order = docRef.data();
          const orderDate = new Date(order.date);
    
          let patientName = ''; // Inițializăm numele pacientului cu un string gol
    
          try {
            // Încercăm să obținem datele pacientului
            const patientRef = await getDoc(doc(db, 'patients', order.patientId));
            const patientData = patientRef.data();
    
            // Verificăm dacă există date pentru pacient și dacă există nume
            if (patientData && patientData.name) {
              patientName = patientData.name; // Utilizăm numele pacientului găsit
            }
          } catch (error) {
            console.error('Error getting patient data:', error);
            // Tratăm cazurile în care nu am putut obține datele pacientului
            // puteți seta, de exemplu, un nume implicit pentru a indica că pacientul este necunoscut
            patientName = 'Unknown';
          }
    
          if (orderDate.getDate() === currentDate.getDate() && orderDate.getMonth() === currentDate.getMonth() && orderDate.getFullYear() === currentDate.getFullYear()) {
            patientsCountDay++;
            earningsDay += parseFloat(order.price);
          }
    
          if (orderDate.getMonth() === currentDate.getMonth() && orderDate.getFullYear() === currentDate.getFullYear()) {
            patientsCountMonth++;
            earningsMonth += parseFloat(order.price);
          }
    
          if (orderDate.getFullYear() === currentDate.getFullYear()) {
            patientsCountYear++;
            earningsYear += parseFloat(order.price);
          }
    
          ordersData.push({
            id: docRef.id,
            patientName: patientName,
            date: order.date,
            reason: order.reason,
            price: order.price,
            patientId: order.patientId,
          });
        }
    
        setTotalPatientsDay(patientsCountDay);
        setTotalPatientsMonth(patientsCountMonth);
        setTotalPatientsYear(patientsCountYear);
        setTotalEarningsDay(earningsDay);
        setTotalEarningsMonth(earningsMonth);
        setTotalEarningsYear(earningsYear);
        setOrders(ordersData);
      } catch (error) {
        console.error('Error getting orders:', error);
      }
    };
    

    fetchOrders();
  }, []);

  const toggleSidebar = () => {
    if (!clickLocked) {
      setSidebarOpen(!sidebarOpen);
      setClickLocked(true);
      setTimeout(() => {
        setClickLocked(false);
      }, 300); // Blocați clicurile timp de 300 de milisecunde
    }
  };

  return (
    <div className='ordersPage'>
    <div className='navbar-side'>
    <Sidebar isOpen={sidebarOpen} />
    </div>
      <div className='ordersContainer'>
      <Navbar toggleSidebar={toggleSidebar} />
        <div className="widgets">
          <Widget type="user" />
          <Widget type="patient" />
          <Widget type="order" />
          <Widget type="earning" />
        </div>
        <div className="details">
            <div className='patientDetails'>
                <h1 className='pDetails'>Pacientii de astazi: <span className='detailsSpan'> {totalPatientsDay} </span></h1>
                <h3 className='pDetails'>Pacientii din luna aceasta: <span className='detailsSpan'>  {totalPatientsMonth}</span></h3>
                <h3 className='pDetails'>Pacientii din anul acesta: <span className='detailsSpan'> {totalPatientsYear}</span></h3>
            </div>
            <div className='earningDetails'>
                <h1 className='pDetails'>Incasarile de astazi: <span className='detailsSpan'>  {totalEarningsDay} $</span></h1>
                <h3 className='pDetails'>Incasarile din luna aceasta: <span className='detailsSpan'>  {totalEarningsMonth} $</span></h3>
                <h3 className='pDetails'>Incasarile din anul acesta: <span className='detailsSpan'>  {totalEarningsYear} $</span></h3>
            </div>
          </div>
        <div className="orders">
          <OrderList/>
        </div>
      </div>
    </div>
  );
};

export default Orders;