import React, { useEffect, useState } from 'react';
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import Widget from "../../components/widget/Widget"
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

import "./stats.scss";

const Stats = () => {

  return (
    <div className='stats'>
      <Sidebar />
      <div className="statsContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="user" />
          <Widget type="patient" />
          <Widget type="order" />
          <Widget type="earning" />
        </div>
      </div>
    </div>
  )
}

export default Stats;
