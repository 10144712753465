import React, { useEffect, useState } from 'react';
import './chart.scss';
import { AreaChart, Area, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { collection, getDocs, addDoc } from "firebase/firestore";
import { db } from "../../firebase";

function Chart({ aspect, title }) {
  const [monthlyIncomeData, setMonthlyIncomeData] = useState([]);

  useEffect(() => {
    const fetchMonthlyIncomeData = async () => {
      try {
        // Obține toate vizitele din colecția "visits"
        const querySnapshot = await getDocs(collection(db, 'visits'));
        const visitsData = querySnapshot.docs.map(doc => doc.data());

        // Initializează un obiect pentru a stoca veniturile lunare
        const monthlyIncome = {};

        // Calculează veniturile lunare pentru fiecare vizită
        visitsData.forEach(data => {
          const date = new Date(data.date);
          const key = `${date.getFullYear()}-${date.getMonth() + 1}`;

          // Verifică dacă există deja un venit pentru această lună, altfel initializează-l cu 0
          if (!monthlyIncome[key]) {
            monthlyIncome[key] = 0;
          }

          // Adaugă prețul vizitei la venitul lunar al lunii respective
          monthlyIncome[key] += parseFloat(data.price);
        });

        // Transformă datele în formatul potrivit pentru Recharts și ordonează-le cronologic
        const formattedData = Object.keys(monthlyIncome)
          .sort()
          .map(key => ({
            name: key,
            Total: monthlyIncome[key],
          }));

        // Actualizează starea cu datele pentru grafic
        setMonthlyIncomeData(formattedData);
      } catch (error) {
        console.error('Error fetching monthly income data:', error);
      }
    };

    fetchMonthlyIncomeData();
  }, []);

  return (
    <div className='chart'>
      <div className='title'>{title}</div>
      <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart width={730} height={250} data={monthlyIncomeData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id='total' x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke='gray' />
          <CartesianGrid strokeDasharray="3 3" className='chartGrid' />
          <Tooltip />
          <Area type="monotone" dataKey="Total" stroke="#8884d8" fillOpacity={1} fill="url(#total)" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Chart;
