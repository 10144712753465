import React, { useEffect, useState } from 'react';
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import Widget from "../../components/widget/Widget"
import Featured from "../../components/featured/Featured"
import Chart from "../../components/chart/Chart"
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import OrderList from '../../components/orderList/OrderList';


import "./home.scss"

const Home = () => {
const [data, setData] = useState([]);
const [sidebarOpen, setSidebarOpen] = useState(false);
const [clickLocked, setClickLocked] = useState(false);

useEffect(() => {
  const unsub = onSnapshot(collection(db, "patients"), (snapshot) => {
    const patientList = snapshot.docs.map(doc => ({ 
      id: doc.id, 
      ...doc.data(),
      date: new Date(doc.data().timeStamp.seconds * 1000).toLocaleDateString("ro-RO", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    }));
    patientList.sort((a, b) => b.timeStamp - a.timeStamp);
    setData(patientList);
  });

  return () => {
    if (unsub) unsub();
  };
}, []); 

  const toggleSidebar = () => {
    if (!clickLocked) {
      setSidebarOpen(!sidebarOpen);
      setClickLocked(true);
      setTimeout(() => {
        setClickLocked(false);
      }, 300); // Blocați clicurile timp de 300 de milisecunde
    }
  };

return (
  <div className='home'>
    <div className='navbar-side'>
    <Sidebar isOpen={sidebarOpen} />
    </div>
      <div className="homeContainer">
      <Navbar toggleSidebar={toggleSidebar} />
        <div className="widgets">
          <Widget className='widget-item' type="user" />
          <Widget className='widget-item' type="patient" />
          <Widget className='widget-item' type="order" />
          <Widget className='widget-item' type="earning" />
        </div>

        <div className="charts">
        <Featured />
        <Chart aspect={2 / 1} title="User Spending (Last 6 Months)" />
        </div>

        <div className="listContainer">
          <div className="listTitle">Ultimele tranzactii:</div>
          <div className='datatable'>
            <OrderList />
          </div>
        </div>
      </div>
  </div>
)
}

export default Home;
