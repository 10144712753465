import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import "./doctorDetails.scss"


const DoctorDetails = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Setează starea de încărcare la începutul preluării datelor
        const docRef = doc(db, "doctors", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserData(docSnap.data());
          setError(null); // Resetează eroarea în caz de succes
        } else {
          setError("User not found!");
        }
      } catch (error) {
        setError("Error getting user data!");
        console.error("Error getting document:", error);
      } finally {
        setLoading(false); // Asigură-te că starea de încărcare se setează și în caz de eroare
      }
    };
  
    fetchData();
  
    return () => {
      // cleanup
    };
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
  return <div>Error: {error}</div>;
}

return (
  <div className='doctorDetails'>
    <Sidebar />
    <div className='singleContainer'>
      <Navbar />
      <div className='top'>
        <div className='left'>
          <div className='item'>
          
            <div className='details'>
              {userData ? (
                <div>
                  <div className='detailItem'>
                    <span className='itemKey'>Display Name:</span>
                    <span className='itemValue'>{userData.displayName}</span>
                  </div>
                  <div className='detailItem'>
                    <span className='itemKey'>Username:</span>
                    <span className='itemValue'>{userData.username}</span>
                  </div>
                  <div className='detailItem'>
                    <span className='itemKey'>Phone:</span>
                    <span className='itemValue'>{userData.phone}</span>
                  </div>
                  <div className='detailItem'>
                    <span className='itemKey'>Email:</span>
                    <span className='itemValue'>{userData.email}</span>
                  </div>
                  <div className='detailItem'>
                    <span className='itemKey'>Country:</span>
                    <span className='itemValue'>{userData.country}</span>
                  </div>
                  <div className='detailItem'>
                    <span className='itemKey'>Address:</span>
                    <span className='itemValue'>{userData.address}</span>
                  </div>
                </div>
              ) : (
                <div>Loading user data...</div> // Mesaj de încărcare în timp ce se preiau datele
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='bottom'>
        <h1 className='title'>Last Transactions</h1>
      </div>
    </div>
  </div>
);
};

export default DoctorDetails;
