import './navbar.scss'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import { useContext } from 'react';
import { DarkModeContext } from '../../contex/darkModeContext';


const Navbar = ({toggleSidebar}) => {

const {dispatch} = useContext(DarkModeContext)
const handleSidebarToggle = () => {
    toggleSidebar();
};


  return (
    <div className='navbar'>
      <div className='wrapper'>
        {/* <div className='search'>
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon/>
        </div> */}
        <div className='items'>
            <div className='item'>
                <LanguageOutlinedIcon className='icon'/>
                English
            </div>
            <div className='item'>
                <DarkModeOutlinedIcon className='icon' onClick={() => dispatch({type:"TOGGLE"})} />
            </div>
            <div className='item'>
                <LocationSearchingOutlinedIcon className='icon'/>
            </div>
            <div className='item'>
                <NotificationsNoneOutlinedIcon className='icon'/>
                <div className='counter'>1</div>
            </div>
            <div className='item'>
                <ChatBubbleOutlineOutlinedIcon className='icon'/>
                <div className='counter'>2</div>
            </div>
            <div className='item hamburger' onClick={toggleSidebar}>
                <FormatListBulletedOutlinedIcon className='icon hamburger-icon'/>
            </div>
            {/* <div className='item'>
                <img
                src='https://images.unsplash.com/photo-1593085512500-5d55148d6f0d?q=80&w=880&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                alt=''
                className='avatar' />
            </div> */}
        </div>
      </div>
    </div>
  )
}

export default Navbar
