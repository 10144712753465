import React, { useEffect, useState } from 'react';
import './widget.scss';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/ArrowDownwardOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useTotalSales } from '../../contex/TotalSalesContext';
import { Link } from 'react-router-dom';


const Widget = ({ type }) => {
  const { totalSalesToday } = useTotalSales();
  const [data, setData] = useState(null);
  const [amount, setAmount] = useState(0);
  const [diff, setDiff] = useState(null);
  const percentage = 20; // Definește procentul profitului aici

  useEffect(() => {
    const fetchData = async () => {
      if (!data || !data.query) {
        console.error('Data or data.query is not defined');
        return;
      }

      try {
        const querySnapshot = await getDocs(collection(db, data.query));
        const totalCount = querySnapshot.docs.length;
        setAmount(totalCount);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (data && data.query) {
      fetchData();
    }
  }, [data]);

  useEffect(() => {
    switch (type) {
      case "user":
        setData({
          title: "DOCTORI",
          isMoney: false,
          link:  <Link style={{ textDecoration: "none", color: "black"}}  to="/doctors">vezi lista de doctori</Link>,
          query: "doctors",
          icon: <Person2OutlinedIcon className='icon' style={{ color: "crimson", backgroundColor: "rgba(255, 165, 32, 0.2)" }} />
        });
        break;
      case "order":
        setData({
          title: "COMENZI",
          isMoney: false,
          link:  <Link style={{ textDecoration: "none", color: "black"}}   to="/orders">vezi lista de comenzi</Link>,
          query: "visits",
          icon: <ShoppingCartOutlinedIcon className='icon' style={{ color: "goldenrod", backgroundColor: "rgba(255, 165, 32, 0.2)" }} />
        });
        break;
      case "earning":
        setData({
          title: "PROFIT",
          isMoney: true,
          amount: totalSalesToday,
          profit: (totalSalesToday * percentage) / 100, // Calculează profitul
          link: "acesta este profitul dvs",
          icon: <MonetizationOnOutlinedIcon className='icon' style={{ color: "green", backgroundColor: "rgba(255, 165, 32, 0.2)" }} />
        });
        break;
      case "patient":
        setData({
          title: "PACIENTI",
          query: "patients",
          link: <Link  style={{ textDecoration: "none", color: "black"}}  to="/patients">vezi lista de pacienti</Link>,
          icon: <AccountBalanceOutlinedIcon className='icon' style={{ color: "purple", backgroundColor: "rgba(255, 165, 32, 0.2)" }} />
        });
        break;
      default:
        break;
    }
  }, [type, totalSalesToday]);

  return (
    <div className='widget'>
      {data && (
        <>
          <div className='left'>
            <span className='title'>{data.title}</span>
            {data.isMoney ? (
              <>

                <span className='counter'>$ {data.profit}</span>

              </>
            ) : (
              <span className='counter'>{data.isMoney && "$"} {amount}</span>
            )}
            <span className='link'>{data.link}</span>
          </div>
          <div className='right'>
            <div className={`percentage ${diff < 0 ? "negative" : "positive"}`}>
              {diff < 0 ? <KeyboardArrowDownIcon /> : <ArrowUpwardOutlinedIcon />}
              {diff} %
            </div>
            {data.icon}
          </div>
        </>
      )}
    </div>
  );
}

export default Widget;
