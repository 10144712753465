import React, { useState } from 'react';
import './new.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { addDoc, collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const New = ({ title }) => {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  // Definirea manuală a dataType în funcție de titlul paginii
  const dataType = title.includes('user') ? 'doctors' : 'patients';

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData((prev) => ({ ...prev, [id]: value }));
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      if (dataType === 'patients' && (!data.name || !data.name.trim())) {
        alert('Numele pacientului este obligatoriu!');
        return;
      }

      if (dataType === 'doctors') {
        if (!data.email || !data.password) {
          alert('Email-ul și parola sunt obligatorii pentru crearea unui cont de utilizator!');
          return;
        }

        // Adaugare utilizator
        const res = await createUserWithEmailAndPassword(auth, data.email, data.password);
        await setDoc(doc(db, 'doctors', res.user.uid), {
          displayName: data.displayName,
          phone: data.phone,
          address: data.address,
          country: data.country,
          email: data.email,
          timeStamp: serverTimestamp(),
        });
      } else if (dataType === 'patients') {
        if (data.cnp && data.cnp.length < 13) {
          alert('CNP-ul trebuie să conțină cel puțin 13 cifre!');
          return;
        }
        // Adaugare pacient
        const capitalizedPatientName = data.name.charAt(0).toUpperCase() + data.name.slice(1);
        await addDoc(collection(db, 'patients'), {
          ...data,
          name: capitalizedPatientName,
          timeStamp: serverTimestamp(),
        });
      }

      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  // Definirea câmpurilor pentru formularul de adăugare pacienți
  const patientInputs = [
    { id: 'name', label: 'Nume', type: 'text', placeholder: 'Introduceți numele' },
    { id: 'cnp', label: 'CNP', type: 'number', placeholder: 'Introduceți CNP-ul' },
    { id: 'nr', label: 'Numarul pacientului', type: 'number', placeholder: 'Numarul pacientului' },
    { id: 'dataInregistrarii', label: 'Data', type: 'date' },
  ];

    // Definirea câmpurilor pentru formularul de adăugare doctori
    const doctorInputs = [
      { id: 'displayName', label: 'Nume utilizator', type: 'text', placeholder: 'Introduceți numele de utilizator' },
      { id: 'email', label: 'Email', type: 'email', placeholder: 'Introduceți adresa de email' },
      { id: 'password', label: 'Parola', type: 'password', placeholder: 'Introduceți parola' },
      { id: 'phone', label: 'Telefon', type: 'text', placeholder: 'Introduceți numărul de telefon' },
      { id: 'address', label: 'Adresa', type: 'text', placeholder: 'Introduceți adresa' },
      { id: 'country', label: 'Țară', type: 'text', placeholder: 'Introduceți țara' },
      { id: 'cnp', label: 'CNP', type: 'number', placeholder: 'Introduceți CNP-ul' },
    ];


    const inputs = dataType === 'patients' ? patientInputs : doctorInputs;

  return (
    <div className='new'>
      <Sidebar />
      <div className='newContainer'>
        <Navbar />
        <div className='top'>
          <h1>{`Adaugare ${dataType === 'patients' ? 'pacient' : 'doctor'} nou`}</h1>
        </div>
        <div className='bottom'>
          <div className='right'>
            <form onSubmit={handleAdd}>
              {inputs.map((input) => (
                (dataType !== 'doctors' || (dataType === 'doctors' && input.id !== 'username')) && (
                  <div className='formInput' key={input.id}>
                    <label>{input.label}</label>
                    {/* Verificăm tipul de input și afișăm inputul corespunzător */}
                    {input.type === 'date' ? (
                      <input id={input.id} type={input.type} onChange={handleInput} />
                    ) : (
                      <input id={input.id} type={input.type} placeholder={input.placeholder} onChange={handleInput} />
                    )}
                  </div>
                )
              ))}
              <button className='sendBtn' type='submit'>
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
