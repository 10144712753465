import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { collection, deleteDoc, doc, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from 'react-router-dom';
import './patientTable.scss'

const PatientTable = () => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const unsub = onSnapshot(collection(db, "patients"), (snapshot) => {
            const patientList = snapshot.docs.map(doc => ({ 
                id: doc.id, 
                ...doc.data(),
                date: doc.data().dataInregistrarii ? new Date(doc.data().dataInregistrarii).toLocaleDateString("ro-RO", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                }) : '', // Utilizăm data introdusă de utilizator, sau un șir gol dacă nu este definită
            }));
            patientList.sort((a, b) => b.timeStamp - a.timeStamp);
            setData(patientList);
        });

        return () => {
            if (unsub) unsub();
        };
    }, []);

    const handleDelete = async (id) => {
        try {
            const confirmDelete = window.confirm("Are you sure you want to delete this item?");
            if (confirmDelete) {
                // Șterge pacientul
                await deleteDoc(doc(db, "patients", id));
                
                // Parcurge lista de vizite și șterge vizitele asociate pacientului șters
                const visitsSnapshot = await getDocs(collection(db, "visits"));
                const visitsToDelete = [];
                visitsSnapshot.forEach((visitDoc) => {
                    const visitData = visitDoc.data();
                    if (visitData.patientId === id) {
                        visitsToDelete.push(deleteDoc(doc(db, "visits", visitDoc.id)));
                    }
                });
    
                // Așteaptă ștergerea tuturor vizitelor
                await Promise.all(visitsToDelete);
    
                // Actualizează starea listei de pacienți pentru a reflecta modificările
                setData(data.filter((item) => item.id !== id));
            }
        } catch (error) {
            console.log(error);
        }
    };
    

    const filteredData = data.filter(item =>
        item && item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className='cellAction'>
                        <Link to={`/patients/${params.row.id}`} style={{ textDecoration: "none" }}>
                            <div className='viewButton'>View</div>
                        </Link>
                        <div className='deleteButton' onClick={() => handleDelete(params.row.id)}>Delete</div>
                    </div>
                );
            }
        }
    ];
    
    const columns = [
        { field: "name", headerName: "Name", width: 250 },
        { field: "nr", headerName: "Nr", width: 120 },
        { field: "date", headerName: "Date", width: 160 },
        { field: "cnp", headerName: "Cnp", width: 210 },
    ];

    return (
        <div className='datatable'>
            <Link to="/patients/new" className='addNewButton'>Add New Patient</Link>
            <input
                className='searchInput'
                type="text"
                placeholder="Search by name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <DataGrid
                className='datagrid'
                rows={filteredData}
                columns={columns.concat(actionColumn)}
                pageSize={9}
                rowsPerPageOptions={[20]}
                checkboxSelection
            />
        </div>
    );
};

export default PatientTable;
