// VisitForm.js
import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import "./visitForm.scss"

const VisitForm = ({patientId}) => {
    const [formData, setFormData] = useState({
      date: new Date().toISOString().slice(0, 10),
      reason: '',
      price: '',
      patientId: patientId, 
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const priceValue = formData.price === '' ? 0 : parseFloat(formData.price);
    try {
      // Adaugarea datelor formularului în baza de date Firebase
      await addDoc(collection(db, 'visits'), {
      ...formData,
      price: priceValue,
      }
      );
      
      // Resetarea starii formularului dupa trimiterea datelor
      setFormData({
        date: new Date().toISOString().slice(0, 10), // Resetam data la data curenta
        reason: '',
        price: '',
        patientId: patientId, // Asiguram ca patientId este setat pentru urmatoarea vizita
        // Alte câmpuri ale formularului
      });
    } catch (error) {
      console.error('Error adding document:', error);
    }
  };

  const handlePriceClick = () => {
    // Verifica daca valoarea prețului este 0 și o șterge daca este
    if (formData.price === 0) {
      setFormData(prevState => ({
        ...prevState,
        price: '',
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className='visitForm'>
        <form className='form' onSubmit={handleSubmit}>
        <h3>Motivul vizitei actuale</h3>
    
        <div className='reason'>
            <label></label>
            <textarea name="reason" value={formData.reason} onChange={handleChange}></textarea>
        </div>

        <div className='date'>
            <label>Date:</label>
            <input type="date" name="date" value={formData.date} onChange={handleChange} />
        </div>
        
        <div className='price'>
          <label className='priceTitle'>Price:</label>
          <input className='priceInput'
          type="number"
          name="price"
          value={formData.price}
          onChange={handleChange}
          onClick={handlePriceClick} />
        </div>

        <button className='submit' type="submit">Submit</button>
        </form>
    </div>
  );
};

export default VisitForm;
