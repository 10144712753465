import { createContext, useContext, useEffect, useState } from 'react';
import { collection, onSnapshot } from "firebase/firestore";
import { db } from '../firebase';

const TotalSalesContext = createContext();

export const useTotalSales = () => useContext(TotalSalesContext);

export const TotalSalesProvider = ({ children }) => {
  const [totalSalesToday, setTotalSalesToday] = useState(0);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'visits'), (querySnapshot) => {
      let totalToday = 0;
      const currentDate = new Date();

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const orderDate = new Date(data.date);
        const price = parseFloat(data.price);

        if (isSameDay(orderDate, currentDate)) {
          totalToday += price;
        }
      });

      setTotalSalesToday(totalToday);
    });

    return () => unsubscribe();
  }, []);

  // Function to check if two dates are on the same day
  const isSameDay = (date1, date2) => {
    return date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear();
  };

  return (
    <TotalSalesContext.Provider value={{ totalSalesToday }}>
      {children}
    </TotalSalesContext.Provider>
  );
};
