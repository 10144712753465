import React from 'react';
import "./patients.scss";
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import DoctorTable from '../../components/doctorDetails/DoctorTable';
import PatientTable from '../../components/patientDetails/PatientTable';
import Widget from '../../components/widget/Widget';
import { useState } from 'react';

const Patients = ({title}) => {

  const [sidebarOpen, setSidebarOpen] = useState(false); 
  const [clickLocked, setClickLocked] = useState(false);

  const toggleSidebar = () => {
    if (!clickLocked) {
      setSidebarOpen(!sidebarOpen);
      setClickLocked(true);
      setTimeout(() => {
        setClickLocked(false);
      }, 300); // Blocați clicurile timp de 300 de milisecunde
    }
  };


  return (
    <div className='list'>
    <div className='navbar-side'>
    <Sidebar isOpen={sidebarOpen} />

    </div>
      <div className='listContainer'>
      <Navbar toggleSidebar={toggleSidebar} />
        <div className="widgets">
          <Widget type="user" />
          <Widget type="patient" />
          <Widget type="order" />
          <Widget type="earning" />
        </div>
            <PatientTable />
      </div>
    </div>
  );
};

export default Patients;
