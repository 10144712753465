import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import './doctorTable.scss'

const DoctorTable = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "doctors"), (snapshot) => {
      const doctorList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(doctorList);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm("Are you sure you want to delete this item?");
      if (confirmDelete) {
        await deleteDoc(doc(db, "doctors", id));
        setData(data.filter(item => item.id !== id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filteredData = data.filter(item =>
    item && item.displayName && item.displayName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    { field: 'displayName', headerName: 'Name', width:100, className: 'nameColumn' },
    { field: 'email', headerName: 'Email', width:100, className: 'emailColumn' },
    { field: 'phone', headerName: 'Phone', width:100, className: 'phoneColumn' },
    { field: 'address', headerName: 'Address', width:100, className: 'addressColumn' },
    { field: 'country', headerName: 'Country', width:100, className: 'countryColumn' },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <div className='cellAction'>
          <Link to={`/doctors/${params.row.id}`} style={{ textDecoration: "none" }}>
            <div className='viewButton'>View</div>
          </Link>
          <div className='deleteButton' onClick={() => handleDelete(params.row.id)}>Delete</div>
        </div>
      )
    }
  ];

  return (
    <div className='datatable'>
      <div className='datatableTitle'>
        <Link to="/doctors/new" className='addNewButton'>Add New Doctor</Link>
      </div>
      <input
        className='searchInput'
        type="text"
        placeholder="Search by Name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <DataGrid
        className='datagrid'
        rows={filteredData}
        columns={columns}
        pageSize={9}
        rowsPerPageOptions={[20]}
        checkboxSelection
      />
    </div>
  );
};

export default DoctorTable;
