import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { doc, addDoc, deleteDoc, getDoc, query, where, orderBy, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import Chart from "../chart/Chart";
import VisitForm from '../visitForm/VisitForm';
import { collection, onSnapshot } from 'firebase/firestore';
import "../patientDetails/patientDetails.scss";


const PatientDetails = () => {
  const { id } = useParams();
  const [patientData, setPatientData] = useState(null);
  const [visits, setVisits] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false); 
  const [clickLocked, setClickLocked] = useState(false);
  const [editVisitId, setEditVisitId] = useState(null);
  const [editVisitData, setEditVisitData] = useState(null);

  const fetchData = async () => {
    try {
      const docRef = doc(db, "patients", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPatientData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };

  const fetchVisits = async () => {
    try {
      const q = query(collection(db, "visits"), where("patientId", "==", id), orderBy("date", "desc"));
      const querySnapshot = await getDocs(q);
      const visitsData = [];
      querySnapshot.forEach((doc) => {
        visitsData.push({ id: doc.id, ...doc.data() });
      });
      setVisits(visitsData);
    } catch (error) {
      console.error("Error getting visits:", error);
    }
  };

  useEffect(() => {
    fetchData();
    console.log('Fetching visits...');
  
    const q = query(collection(db, "visits"), where("patientId", "==", id), orderBy("date", "desc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const visitsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setVisits(visitsData);
    });
  
    return () => {
      unsubscribe();
    };
  }, [id]);
  
  const handleSaveVisit = async (visitData) => {
    try {
      await addDoc(collection(db, 'visits'), {
        patientId: id,
        ...visitData,
      });
      // Reîncarcă vizitele după adăugare
      fetchVisits();
    } catch (error) {
      console.error('Error saving visit:', error);
    }
  };

  const handleDeleteVisit = async (visitId) => {
    try {
      await deleteDoc(doc(db, 'visits', visitId));
      // Reîncarcă vizitele după ștergere
      fetchVisits();
    } catch (error) {
      console.error('Error deleting visit:', error);
    }
  };

  const handleEditVisit = async (visitId) => {
    try {
      const visitRef = doc(db, 'visits', visitId);
      const visitDoc = await getDoc(visitRef);
      if (visitDoc.exists()) {
        const visitData = visitDoc.data();
        setEditVisitId(visitId);
        setEditVisitData(visitData); // Actualizați editVisitData cu detaliile vizitei
      } else {
        console.log("No such visit document!");
      }
    } catch (error) {
      console.error('Error getting visit document:', error);
    }
  };
  
  const saveEditedVisit = async () => {
    try {
      const visitRef = doc(db, 'visits', editVisitId);
      await updateDoc(visitRef, editVisitData); // Actualizați detaliile vizitei în baza de date
      // Reîncarcă vizitele după actualizare
      fetchVisits();
      setEditVisitId(null);
      setEditVisitData(null);
    } catch (error) {
      console.error('Error updating visit:', error);
    }
  };
  

  const toggleSidebar = () => {
    if (!clickLocked) {
      setSidebarOpen(!sidebarOpen);
      setClickLocked(true);
      setTimeout(() => {
        setClickLocked(false);
      }, 300); // Blocați clicurile timp de 300 de milisecunde
    }
  };

  return (
    <div className='patientDetails'>
      <div className='navbar-side'>
        <Sidebar isOpen={sidebarOpen} />
      </div>
      <div className='singleContainer'>
      <Navbar toggleSidebar={toggleSidebar} />
        <div className='top'>
          <div className='left'>
            <div className='item'>
              <div className='details'>
                <div className='detailItem'>
                  <span className='itemKey'>Name:</span>
                  <span className='itemValue'>{patientData ? patientData.name : 'Loading...'}</span>
                </div>
                <div className='detailItem'>
                  <span className='itemKey'>Date:</span>
                  <span className='itemValue'>{patientData ? new Date(patientData.timeStamp?.seconds * 1000).toLocaleDateString("ro-RO", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    }) : 'Loading...'}
                  </span>
                </div>
                <div className='detailItem'>
                  <span className='itemKey'>CNP:</span>
                  <span className='itemValue'>{patientData ? patientData.cnp : 'Loading...'}</span>
                </div>
                <div className='detailItem'>
                  <span className='itemKey'>Nr:</span>
                  <span className='itemValue'>{patientData ? patientData.nr : 'Loading...'}</span>
                </div>
                <div className='detailItem'>
                  <span className='itemKey'>Date Registered:</span>
                  <span className='itemValue'>{patientData ? new Date(patientData.timeStamp?.toDate()).toLocaleString("ro-RO") : 'Loading...'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bottom'>
          <div className='visits'>
            <h1 className='title'>Visits History</h1>
            <div className='visitForm'><VisitForm patientId={id} onSave={handleSaveVisit} /></div>
            <ul className='listaIstoric'>
              {visits.length > 0 ? (
                visits.map((visit) => (
                  <li className='li' key={visit.id}>
                    {editVisitId === visit.id ? (
                      <div className='editVisitContainer'>
                        <input
                          type="text"
                          value={editVisitData.date}
                          onChange={(e) => setEditVisitData({ ...editVisitData, date: e.target.value })}
                        />
                        <input
                          type="text"
                          value={editVisitData.reason}
                          onChange={(e) => setEditVisitData({ ...editVisitData, reason: e.target.value })}
                        />
                        <input
                          type="text"
                          value={editVisitData.price}
                          onChange={(e) => setEditVisitData({ ...editVisitData, price: e.target.value })}
                        />
                        <button className='save-btn' onClick={saveEditedVisit}>Save</button>
                      </div>
                    ) : (
                      <div>
                        <div className='details'>
                          <span className='spanItem'>Date:</span> {visit.date}<br />
                          <span className='spanItem'>Reason:</span> {visit.reason}<br />
                          <span className='spanItem'>Price:</span> {visit.price} $<br />
                        </div>
                        <div className='buttons'>
                        <button 
                          className={editVisitId === visit.id ? "editButton editing" : "editButton"} 
                          onClick={() => handleEditVisit(visit.id)}
                        >
                          Edit
                        </button>

                        <button className='deleteButton' onClick={() => handleDeleteVisit(visit.id)}>Șterge</button>
                        </div>
                      </div>
                    )}
                  </li>
                ))
              ) : (
                <li>No visits available.</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;
