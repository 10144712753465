export const userInputs = [
    {
        id:"username",
        label: "Username",
        type: "text",
        placeholder: "Enter your nikname here..."
    },
    {
        id:"displayName",
        label: "Name and surname",
        type: "text",
        placeholder: "Enter your fullname here..."
    },
    {
        id:"email",
        label: "Email",
        type: "mail",
        placeholder: "Enter your email here..."
    },
    {
        id:"phone",
        label: "Phone",
        type: "text",
        placeholder: "Enter your phone numbere here..."
    },
    {
        id:"password",
        label: "Password",
        placeholder: "Enter your password here...",
        type: "password",
    },
    {
        id:"address",
        label: "Address",
        type: "text",
        placeholder: "Enter your address here..."
    },
    {
        id:"country",
        label: "Country",
        type: "text",
        placeholder: "Enter your country here..."
    }
]

export const patientInputs = [

    {
        id: "name",
        label: "Name",
        type: "text",
        placeholder: "Full name..."
    },
    {
        id: "nr",
        label: "Nr",
        type: "text",
        placeholder: "Number of file..."
    },
    {
        id: "cnp",
        label: "Cnp",
        type: "text",
        placeholder: "Personal numeric code..."
    },
    {
        id: "dataInregistrarii",
        label: "Data",
        type: "date",
        placeholder: "Data...",
    },
]