import React, { useEffect, useState } from 'react';
import './featured.scss'
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import { useTotalSales } from '../../contex/TotalSalesContext';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

function Featured() {
  const { totalSalesToday } = useTotalSales(); // Utilizează totalSalesToday din contextul TotalSalesContext
  const [currentTarget, setCurrentTarget] = useState(0);
  const [lastWeekSales, setLastWeekSales] = useState(0);
  const [lastMonthSales, setLastMonthSales] = useState(0);

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const currentDate = new Date();
        const lastWeek = new Date(currentDate); // Copy the current date
        lastWeek.setDate(currentDate.getDate() - 7);
        const lastMonth = new Date(currentDate); // Copy the current date
        lastMonth.setMonth(currentDate.getMonth() - 1);

        const querySnapshot = await getDocs(collection(db, 'visits'));
        const salesData = querySnapshot.docs.map(doc => doc.data());

        let totalLastWeek = 0;
        let totalLastMonth = 0;

        salesData.forEach(data => {
          const orderDate = new Date(data.date);
          const price = parseFloat(data.price);

          if (orderDate > lastWeek && orderDate <= currentDate) {
            totalLastWeek += price;
          }

          if (orderDate > lastMonth && orderDate <= currentDate) {
            totalLastMonth += price;
          }
        });

        setLastWeekSales(totalLastWeek);
        setLastMonthSales(totalLastMonth);

        setCurrentTarget(15000); // Set a default target

        // Adjust target based on specific conditions
        if (totalSalesToday === 20000 || totalSalesToday === 14000) {
          setCurrentTarget(15000);
        } else if (totalSalesToday === 15000) {
          if (lastWeekSales === 5000 && totalSalesToday - lastWeekSales === 10000) {
            setCurrentTarget(10000);
          } else if (lastMonthSales === 10000 && totalSalesToday - lastMonthSales === 5000) {
            setCurrentTarget(5000);
          }
        }
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };

    fetchSalesData();
  }, [totalSalesToday]);

  return (
    <div className='featured'>
      <div className='top'>
        <h1 className='title'>Total Revenue</h1>
        <MoreVertIcon fontSize='small' />
      </div>
      <div className='bottom'>
        <div className='featuredChart'>
          <CircularProgressbar value={totalSalesToday / currentTarget * 100} text={`${(totalSalesToday / currentTarget * 100).toFixed(2)}%`} strokeWidth={5} />
        </div>
        <p className='title'>Total sales made today</p>
        <p className='amount'>$ {totalSalesToday}</p>
        <p className='desc'>These are the revenues from last week and last month.</p>
        <div className='summary'>
          <div className='item'>
            <div className='itemTitle'>Current Target</div>
            <div className={`itemResult ${(totalSalesToday, currentTarget).color} `}>
              {(totalSalesToday, currentTarget).icon}
              <div className='resultAmount' style={{ color: "green" }}>$ {currentTarget}</div>
            </div>
          </div>
          <div className='item'>
            <div className='itemTitle'>Last Week</div>
            <div className={`itemResult ${(lastWeekSales, currentTarget).color}`}>
              {(lastWeekSales, currentTarget).icon}
              <div className='resultAmount' style={{ color: "green" }}>
                $ {lastWeekSales}
              </div>
            </div>
          </div>
          <div className='item'>
            <div className='itemTitle'>Last Month</div>
            <div className={`itemResult ${(lastMonthSales, currentTarget).color}`}>
              {(lastMonthSales, currentTarget).icon}
              <div className='resultAmount' style={{ color: "green" }}>
                $ {lastMonthSales}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Featured;


