import './sidebar.scss'
import DashboardIcon from '@mui/icons-material/Dashboard';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { DarkModeContext } from '../../contex/darkModeContext';
import { useContext, useState, useEffect } from 'react';
import { auth } from '../../firebase';

const Sidebar = ({ isOpen }) => {
  const { dispatch } = useContext(DarkModeContext);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState('');

  const handleLogout = async () => {
    try {
      await auth.signOut();
      Navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  useEffect(() => {
    // Get the pathname from the location object
    const { pathname } = location;
    setActiveItem(pathname);
  }, [location]);

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <div className='top'>
        <Link to="/" style={{textDecoration:"none"}}>
          <span className='logo'>Admin Panel</span>
        </Link>
      </div>
      <hr />
      <div className='center'>
        <ul>
          <p className='title'>MAIN</p>
          <Link to="/" style={{textDecoration:"none"}}>
            <li className={activeItem === "/" ? 'active' : ''}><DashboardIcon className='icon'/><span>Acasă</span></li>
          </Link>

          <p className='title'>LISTS</p>
          <Link to="/doctors" style={{textDecoration:"none"}}>
            <li className={activeItem === "/doctors" ? 'active' : ''}><Person2OutlinedIcon className='icon'/><span>Doctori</span></li>
          </Link>
          <Link to="/patients" style={{textDecoration:"none"}}>
            <li className={activeItem === "/patients" ? 'active' : ''}><StoreMallDirectoryOutlinedIcon className='icon'/><span>Pacienți</span></li>
          </Link>
          <Link to="/orders" style={{textDecoration:"none"}}>
            <li className={activeItem === "/orders" ? 'active' : ''}><DvrOutlinedIcon className='icon'/><span>Comenzi</span></li>
          </Link>
          <Link to="/appointments" style={{textDecoration:"none"}}>
            <li className={activeItem === "/appointments" ? 'active' : ''}><QueryBuilderIcon className='icon'/><span>Programări</span></li>
          </Link>

          <li onClick={handleLogout}><LogoutOutlinedIcon className='icon' /><span>Delogheaza-te</span></li> 
        </ul>
      </div>
      <div className='bottom'>
        <div className='colorOption' onClick={() => dispatch({type:"LIGHT"})}></div>
        <div className='colorOption' onClick={() => dispatch({type:"DARK"})}></div>
      </div>
    </div>
  )
}

export default Sidebar;
