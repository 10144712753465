import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from "../../firebase";
import { Link } from 'react-router-dom';
import './orderList.scss';

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // numărul de elemente afișate pe pagină
  const [searchTerm, setSearchTerm] = useState(''); // Starea pentru termenul de căutare

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'visits'));
        const ordersData = [];
  
        for (const docRef of querySnapshot.docs) {
          const order = docRef.data();
          const patientRef = await getDoc(doc(db, 'patients', order.patientId));
          const patientData = patientRef.data();

          let patientName = '';

          if (patientData && patientData.name) {
            patientName = patientData.name; // Utilizăm numele pacientului găsit
          } else {
            patientName = 'Unknown'; // Dacă nu găsim un nume de pacient, setăm un nume implicit
          }
  
          ordersData.push({
            id: docRef.id,
            patientName: patientName,
            date: order.date,
            reason: order.reason,
            price: order.price,
            patientId: order.patientId,
            timestamp: order.timestamp
          });
        }
        // Sortăm lista în ordine descrescătoare a datelor
        ordersData.sort((a, b) => new Date(b.date) - new Date(a.date));
        setOrders(ordersData);
      } catch (error) {
        console.error('Error getting orders:', error);
      }
    };
  
    fetchOrders();
  }, []);

  
  

  const renderViewButton = (patientId) => (
    <Link to={`/patients/${patientId}`}>
      <button className='viewBtn'>View</button>
    </Link>
  );

  const filteredOrders = orders.filter(order =>
    order.patientName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Funcție pentru a returna lista paginată după filtrare
  const paginatedOrders = filteredOrders.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Funcții pentru navigarea între pagini
  const nextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Resetează pagina curentă la prima pagină la fiecare modificare a termenului de căutare
  };



  return (
    <div className='datagridOrders'>
    <input
        className='searchInput'
        type="text"
        placeholder="Cauta in lista de comenzi..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <table className='datagrid'>
        <thead>
          <tr className='tableRow'>
            <th className='nameColumn column'>Numele pacientului</th>
            <th className='dateColumn column'>Data</th>
            <th className='reasonColumn column'>Motivul vizitei</th>
            <th className='priceColumn column'>Costul vizitei</th>
            <th>Actiuni</th>
          </tr>
        </thead>
        <tbody>
          {paginatedOrders.map(order => (
            <tr key={order.id}>
              <td>{order.patientName}</td>
              <td>{order.date}</td>
              <td>{order.reason}</td>
              <td>${order.price}</td>
              <td>{renderViewButton(order.patientId)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
        <button onClick={nextPage} disabled={currentPage === Math.ceil(orders.length / itemsPerPage)}>Next</button>
      </div>
    </div>
  );
};

export default OrderList;
