import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { DarkModeContextProvider } from './contex/darkModeContext';
import { AuthContextProvider } from './contex/AuthContext';
import { TotalSalesProvider } from './contex/TotalSalesContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
  <DarkModeContextProvider>
    <AuthContextProvider> 
    <TotalSalesProvider>
      <App />
    </TotalSalesProvider>
    </AuthContextProvider>
  </DarkModeContextProvider> 
  </React.StrictMode>
);


