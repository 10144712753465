

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importă useNavigate din react-router-dom
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import Widget from "../../components/widget/Widget"
import { collection, addDoc, doc, deleteDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

import "./appointments.scss";

const Appointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [patients, setPatients] = useState([]);
  const navigate = useNavigate(); // Inițializează hook-ul navigate
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [clickLocked, setClickLocked] = useState(false);



  const [formData, setFormData] = useState({
    patientName: '',
    appointmentDate: '',
    appointmentTime: '',
    details: ''
  });

  useEffect(() => {
    fetchDataFromFirestore();
  }, []);

  const fetchDataFromFirestore = async () => {
    try {
      const patientsSnapshot = await getDocs(collection(db, 'patients'));
      const patientsData = patientsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPatients(patientsData);

      const appointmentsSnapshot = await getDocs(collection(db, 'appointments'));
      const appointmentsData = appointmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAppointments(appointmentsData);
    } catch (error) {
      console.error('Eroare la obținerea datelor din Firestore:', error);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { appointmentTime, details } = formData;

    try {
      await addDoc(collection(db, 'appointments'), { ...formData, details });
      setFormData({
        patientName: '',
        appointmentDate: '',
        appointmentTime: ''
      });
      fetchDataFromFirestore();
    } catch (error) {
      console.error('Eroare la adăugarea programării:', error);
    }
  };

  const sortAppointmentsByTime = (a, b) => {
    const timeA = new Date(`${a.appointmentDate}T${a.appointmentTime}`);
    const timeB = new Date(`${b.appointmentDate}T${b.appointmentTime}`);
    return timeA - timeB;
  };

  const handleDeleteAppointment = async (appointmentId) => {
    try {
      await deleteDoc(doc(db, 'appointments', appointmentId));
      console.log('Programarea a fost ștearsă cu succes.');
      fetchDataFromFirestore();
    } catch (error) {
      console.error('Eroare la ștergerea programării:', error);
    }
  };

  const handlePatientDetails = (appointment) => {
    const patient = patients.find(patient => patient.name === appointment.patientName);
    if (patient) {
      // Navigăm către pagina pacientului
      navigate(`/patients/${patient.id}`);
    } else {
      // Redirectăm către pagina pentru adăugarea unui pacient nou
      navigate('/patients/new');
    }
  };

  const toggleSidebar = () => {
    if (!clickLocked) {
      setSidebarOpen(!sidebarOpen);
      setClickLocked(true);
      setTimeout(() => {
        setClickLocked(false);
      }, 300); // Blocați clicurile timp de 300 de milisecunde
    }
  };

  return (
    <div className='appointments'>
      <div className='navbar-side'>
        <Sidebar isOpen={sidebarOpen} />
      </div>
      <div className="appointmentsContainer">
      <Navbar toggleSidebar={toggleSidebar} />
        <div className="widgets">
          <Widget type="user" />
          <Widget type="patient" />
          <Widget type="order" />
          <Widget type="earning" />
        </div>
        <div className="appointmentForm">
          <h2 className='title'>Add new appointment</h2>
          <form onSubmit={handleSubmit}>
            <input className='nameInput' type="text" list="patients" name="patientName" value={formData.patientName} onChange={handleFormChange} placeholder="Patient's name" required />
            <datalist id="patients">
              {patients.map(patient => (
                <option key={patient.id} value={patient.name} />
              ))}
            </datalist>
            <input className='dateInput' type="date" name="appointmentDate" value={formData.appointmentDate} onChange={handleFormChange} min={new Date().toISOString().split('T')[0]} required />
            <input className='timeInput' type="text" name="appointmentTime" value={formData.appointmentTime} onChange={handleFormChange} placeholder="Time (HH:MM)" pattern="^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$" title="Please respect this format (HH:MM). Ex: 12:00" required />
            <input className='detailsInput' type="text" name="details" value={formData.details} onChange={handleFormChange} placeholder="Details.." />
           <div className='btnInput'>
            <button className='buttonInput' type="submit">Add to list</button>
            </div>
          </form>
        </div>
        <div className="appointmentList">
          <h2>Appointments List</h2>
          <ul>
          {appointments.sort(sortAppointmentsByTime).map(appointment => (
            <li className='liElement' key={appointment.id}>
              <p className='name item'>Name: <span className='name'>{appointment.patientName}</span></p>
              <p className='date item'>Date: <span className='date'>{appointment.appointmentDate}</span></p>
              <p className='time item'>Time: <span className='time'>{appointment.appointmentTime}</span></p>
              <p className='details item'>Details: <span className='details'>{appointment.details}</span></p> {/* Adăugați acest rând pentru detalii */}
              <div className='buttons'>
                <button className='deleteBtn' onClick={() => handleDeleteAppointment(appointment.id)}>Delete</button>
                <button className='patientBtn' onClick={() => handlePatientDetails(appointment)}>
                  {patients.find(patient => patient.name === appointment.patientName) ?
                    'Patient Details' : 'Add'}
                </button>
              </div>
            </li>
          ))}

          </ul>
        </div>
      </div>
    </div>
  )
}

export default Appointments;
